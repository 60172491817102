<template>
  <div class="home">
    <Navigations :routerValue="routerValue" :webInfo="Info" />
    <!-- <sidebarRight /> -->
    <div class="banner">
      <el-carousel :interval="5000" arrow="always" v-if="bannerList.length > 0">
        <el-carousel-item  v-for="item in bannerList" :key="item.id">
          <img :src="item.img" style="width:100%; height: 850px;" alt="">
        </el-carousel-item>
      </el-carousel>
      <el-carousel v-else>
        <el-carousel-item>
          <img src="../assets/img/newBanner.png" alt="" style="width: 100%;height: 850px;">
        </el-carousel-item>
      </el-carousel>
      <!-- <div class="banner_box">
        <div class="title">
          <span>合</span>
          <span>规</span>
          <span>高</span>
          <span>效</span>
          <span>低</span>
          <span>价</span>
        </div>
        <div class="company">全渠道药品电商运营平台</div>
      </div> -->
    </div>
    <div class="introduce">
      <div class="electronic">
        <div class="electronic_box">
          <div class="left_box">
            <div>MIMO</div>
            <div>米墨</div>
            <div></div>
            <div>
              &nbsp; &nbsp;&nbsp; &nbsp;米墨是一家为医药营销企业,提供数字学术服务、项目标准化、集中合规采购的平台。以独创的服务溯源专利技术为基础,提供一站式、多品类、多模式和多资源服务,通过不断完善合规控制体系、质量监督体系、结算评价体系、供应商资格体系、安全支付体系、税务管理与筹划体系,实现了平台交易流程规范化、交易产品标准化、交易成果数字化、服务过程合规化的核心竞争力。<br>
              &nbsp; &nbsp;&nbsp; &nbsp;米墨致力于为药械厂商提供深度学术推广及精准营销升级服务,为医药从业者提供转型资源,通过合规化营销、精准化服务帮助所有医药人实现数字化发展;帮助行业打破发展壁垒，为行业发展提供新方案和新模式，实现真合规、低成本、高效率、规模化营销。
            </div>
          </div>
          <div class="right_box">
            <img src="../assets/img/project.png" alt="">
          </div>
        </div>
      </div>
      <div class="option">
        <div class="title">
          <img src="../assets/img/u4315.png" alt="">
          <div>服务供应商合规控制体系</div>
          <div></div>
        </div>
        <div class="option_box">
          <el-row :gutter="20">
            <el-col :span="8">
              <el-card shadow="always">
                <div class="plates">
                  <img src="../assets/img/u4330.svg" alt="">
                  <div>标准化资质实体</div>
                </div>
              </el-card>
            </el-col>
            <el-col :span="8">
              <el-card shadow="always">
                <div class="plates">
                  <img src="../assets/img/u4376.svg" alt="">
                  <div>信息化风控管理体系</div>
                </div>
              </el-card>
            </el-col>
            <el-col :span="8">
              <el-card shadow="always">
                <div class="plates">
                  <img src="../assets/img/u4377.svg" alt="">
                  <div>理念认同团队规模</div>
                </div>
              </el-card>
            </el-col>
          </el-row>
          <div style="height: 60px;"></div>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-card shadow="always">
                <div class="plates">
                  <img src="../assets/img/u4378.svg" alt="">
                  <div>药企的终端人员-医生经纪人控转型模式</div>
                </div>
              </el-card>
            </el-col>
            <el-col :span="8">
              <el-card shadow="always">
                <div class="plates">
                  <img src="../assets/img/u4379.svg" alt="">
                  <div>全网代理与接入体系</div>
                </div>
              </el-card>
            </el-col>
            <el-col :span="8">
              <el-card shadow="always">
                <div class="plates">
                  <img src="../assets/img/u4380.svg" alt="">
                  <div>药企的项目经理合规质监模式</div>
                </div>
              </el-card>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="business">
        <div class="title">
          <img src="../assets/img/u4315.png" alt="">
          <div>任务场景</div>
          <div></div>
        </div>
        <div class="business_box">
          <div class="business_box_left">
            <div class="list">
              <div class="recommend" :class="index == 1 ? 'mickActive' : ''">
                <img src="../assets/img/date-comes-back.svg" alt="">
                <div @click="index = 1">科研类</div>
              </div>
              <transition name="el-zoom-in-center">
                <div class="mock" v-if="index == 1" @click="index = 0">
                  <el-card class="box-card">
                    <div class="item">
                      <img src="../assets/img/date-comes-back.svg" alt="">
                      <div class="box">
                        <!-- <div class="title">临床数据研究</div> -->
                        <div class="describe">
                          <!-- <div>•</div> -->
                          临床数据研究
                        </div>
                        <!-- <div class="describe">
                          <div>•</div>主打线上销售
                        </div> -->
                      </div>
                    </div>
                  </el-card>
                </div>
              </transition>
            </div>
            <div class="list">
              <div class="recommend" :class="index == 2 ? 'mickActive' : ''">
                <img src="../assets/img/market-analysis.svg" alt="">
                <div @click="index = 2">营销类</div>
              </div>
              <transition name="el-zoom-in-center">
                <div class="mock" v-if="index == 2" @click="index = 0">
                  <el-card class="box-card">
                    <div class="item">
                      <img src="../assets/img/market-analysis.svg" alt="">
                      <div class="box">
                        <!-- <div class="title">电商市场管理 </div> -->
                        <div class="describe">
                          <!-- <div>•</div> -->
                          流向调查、终端管理、跟台服务、信息调研、患者教育、患者随访、（药店/店员）随访、市场调研、客户开发、客户维护、在线调研、销售跟进、患教会议等
                        </div>
                        <!-- <div class="describe">
                          <div>•</div>修订线下销售合同
                        </div> -->
                      </div>
                    </div>
                  </el-card>
                </div>
              </transition>
            </div>
            <div class="list">
              <div class="recommend" :class="index == 3 ? 'mickActive' : ''">
                <img src="../assets/img/classroom.svg" alt="">
                <div @click="index = 3">学术类</div>
              </div>
              <transition name="el-zoom-in-center">
                <div class="mock" v-if="index == 3" @click="index = 0">
                  <el-card class="box-card">
                    <div class="item">
                      <img src="../assets/img/classroom.svg" alt="">
                      <div class="box">
                        <!-- <div class="title">布网上架 规范展开营销渠道</div> -->
                        <div class="describe">
                          <!-- <div>•</div> -->
                          线下科会、学术培训、技术培训、线上会议等
                        </div>
                      </div>
                    </div>
                  </el-card>
                </div>
              </transition>
            </div>
            <div class="list">
              <div class="recommend" :class="index == 4 ? 'mickActive' : ''">
                <img src="../assets/img/tool.svg" alt="">
                <div @click="index = 4">技术类</div>
              </div>
              <transition name="el-zoom-in-center">
                <div class="mock" v-if="index == 4" @click="index = 0">
                  <el-card class="box-card">
                    <div class="item">
                      <img src="../assets/img/tool.svg" alt="">
                      <div class="box">
                        <!-- <div class="title">品牌矩阵推广</div> -->
                        <div class="describe">
                          <!-- <div>•</div> -->
                          设备巡检、设备维修、软件维护、技术跟台、跟台服务等
                        </div>
                        <!-- <div class="describe">
                          <div>•</div>帮助企业完成互联网化形象设计
                        </div> -->
                      </div>
                    </div>
                  </el-card>
                </div>
              </transition>
            </div>
            <div class="list">
              <div class="recommend" :class="index == 5? 'mickActive' : ''">
                <img src="../assets/img/all-application.svg" alt="">
                <div @click="index = 5">管理类</div>
              </div>
              <transition name="el-zoom-in-center">
                <div class="mock" v-if="index == 5" @click="index = 0">
                  <el-card class="box-card">
                    <div class="item">
                      <img src="../assets/img/all-application.svg" alt="">
                      <div class="box">
                        <!-- <div class="title">品牌矩阵推广</div> -->
                        <div class="describe">
                          <!-- <div>•</div> -->
                          培训-员工培训、营销咨询、咨询-学术咨询、原料产地监控服务、储运过程监控服务、库存盘点监控服务、技术培训等
                        </div>
                        <!-- <div class="describe">
                          <div>•</div>帮助企业完成互联网化形象设计
                        </div> -->
                      </div>
                    </div>
                  </el-card>
                </div>
              </transition>
            </div>
          </div>
          <div class="business_box_right">
          </div>
        </div>
      </div>
      <div class="product">
        <div class="title">
          <img src="../assets/img/u4315.png" alt="">
          <div>业务介绍</div>
          <div></div>
        </div>
        <div class="matrix">
          <div class="inner">
            <img src="../assets/img/buddy.png" alt="">
            <div class="text">
              <div>内控合规</div>
              <div>筛查合规业务风险集中问题<br/>实现稽查要求的内控环境<br/><span style="font-weight: bold">助力合规体系打造</span> </div>
            </div>
          </div>
          <div class="inner">
            <img src="../assets/img/businesses.png" alt="">
            <div class="text">
              <div>业务合规</div>
              <div>提高传统营销合规方案营销效果<br/>落实营销项目支出的合理性<br/><span style="font-weight: bold">助力营销提效增值</span> </div>
              <!--<div>解决传统合规方案无营<br/>销价值问题落实具有真<br/>正价值的营销项目</div>-->
            </div>
          </div>
          <div class="inner">
            <img src="../assets/img/compliance.png" alt="">
            <div class="text">
              <div>主体合规</div>
              <div>把控下游营销服务企业资质风险<br/>把控终端人力关系风险，<br/>杜绝商业贿赂<br/><span style="font-weight: bold">助力各方安全合作</span> </div>
              <!--<div>解决下游服务企业资质<br/>风险完全杜绝商业贿赂</div>-->
            </div>
          </div>
          <div class="inner">
            <img src="../assets/img/cooperation.png" alt="">
            <div class="text">
              <div>税务合规</div>
              <div>辅助服务企业、个人税务筹划<br/>现代征、代扣、代缴、代开<br/><span style="font-weight: bold">助力财税全链清晰</span> </div>
              <!--<div>解决服务企业、个人税<br/>务筹划实现代征、代扣<br/>代缴、代开</div>-->
            </div>
          </div>
        </div>
        <div class="matrix_mock">
          <div>借助医药电商趋势与营销团队升级为契机</div>
          <div>以精准市场授权与合规管理为基础</div>
          <div>全场景品牌推广与利益融合为抓手</div>
          <div>深度触达消费者与提升品牌认知为目的</div>
          <div>实现领先行业的品牌新生态</div>
        </div>
      </div>
      <div class="compete">
        <div class="title">
          <img src="../assets/img/u4315.png" alt="">
          <div>核心竞争力</div>
          <div></div>
        </div>
        <!-- <div class="description">一体化助力客户实现品牌产品的保值（线上维价）、增值（数字市场）与营销上量
          <el-divider></el-divider>
        </div> -->
        <div style="height: 30px;"></div>
        <div class="description_box">
          <div class="inner_box">
            <img src="../assets/img/deal.jpg" alt="">
            <div>交易主体合规体系</div>
          </div>
          <div class="inner_box">
            <img src="../assets/img/personnel.jpg" alt="">
            <div>建立交易主体人事管理及控制</div>
          </div>
          <div class="inner_box">
            <img src="../assets/img/glasses.jpg" alt="">
            <div>搭建服务供应商财税合规审计系统</div>
          </div>
          <div class="inner_box">
            <img src="../assets/img/mark.jpg" alt="">
            <div>服务交易项目执行合规管理体系</div>
          </div>
          <!-- <div class="inner_box">
            <img src="../assets/img/u4375.svg" alt="">
            <div>业绩对赌</div>
          </div> -->
        </div>
      </div>
      <div class="cooperation">
        <div class="title">
          <img src="../assets/img/u4315.png" alt="">
          <div>合作药企</div>
          <div></div>
        </div>
        <div class="partner">
          <div class="swiper-container">
            <div class="swiper-wrapper">
              <div class="swiper-slide swiper-no-swiping">
                <img src="../assets/img/swiper1.png" alt="">
              </div>
              <div class="swiper-slide swiper-no-swiping">
                <img src="../assets/img/swiper2.png" alt="">
              </div>
              <div class="swiper-slide swiper-no-swiping">
                <img src="../assets/img/swiper3.png" alt="">
              </div>
              <div class="swiper-slide swiper-no-swiping">
                <img src="../assets/img/swiper4.png" alt="">
              </div>
              <div class="swiper-slide swiper-no-swiping">
                <img src="../assets/img/swiper5.png" alt="">
              </div>
              <div class="swiper-slide swiper-no-swiping">
                <img src="../assets/img/swiper6.png" alt="">
              </div>
              <div class="swiper-slide swiper-no-swiping">
                <img src="../assets/img/swiper7.png" alt="">
              </div>
              <div class="swiper-slide swiper-no-swiping">
                <img src="../assets/img/swiper8.png" alt="">
              </div>
              <div class="swiper-slide swiper-no-swiping">
                <img src="../assets/img/swiper9.png" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <el-backtop :bottom="500" :visibility-height="3900">
        <div style="{
                height: 100%;
                width: 100%;
                background-color: #f2f5f6;
                box-shadow: 0 0 6px rgba(0,0,0, .12);
                text-align: center;
                line-height: 40px;
                color: #1989fa;
              }">
          UP
        </div>
      </el-backtop>
    </div>
    <bottom :webInfo="Info"  v-if="Object.keys(Info).length!=0"/>
  </div>
</template>

<script>
import Navigations from '@/components/Navigation.vue'
import bottom from '@/components/bottom.vue'
import sidebarRight from '@/components/sidebarRight.vue'
import Swiper, { Navigation, Pagination, Autoplay } from 'swiper'
import 'swiper/swiper-bundle.css'
import { info, banner } from '@/api/home'
Swiper.use([Autoplay, Navigation, Pagination])
export default {
  name: 'Home',
  components: {
    Navigations,
    bottom,
    // eslint-disable-next-line vue/no-unused-components
    sidebarRight
  },
  data () {
    return {
      index: 0,
      routerValue: 'serviceDetails',
      Info: {},
      bannerList: [],
      id: 2 // 米墨id为2
    }
  },
  mounted () {
    // eslint-disable-next-line no-undef, no-unused-vars
    var swiper = new Swiper('.swiper-container', {
      speed: 4000,
      slidesPerView: 5,
      spaceBetween: 30,
      loop: true,
      loopedSlides: 4,
      loopAdditionalSlides: 2,
      allowTouchMove: false,
      autoplay: {
        delay: 1,
        // stopOnLastSlide: false,
        disableOnInteraction: false
      }
    })
  },
  created () {
    info(this.id).then(res => {
      if (res.code === 200) {
        this.Info = res.data
        console.log(22)
      }
    })
    banner(this.id).then(res => {
      if (res.code === 200) {
        this.bannerList = res.data
      }
    })
  }
}
</script>
<style scoped lang="scss">
::v-deep .el-carousel__container {
  height: 850px;
}

.home {
  width: 100%;
  // background-color: #f5f5f5;
  .banner {
    width: 100%;
    height: 850px;

    .banner_box {
      position: absolute;
      left: 50%;
      top: 30%;

      transform: translateX(-50%);
      z-index: 2;
      text-align: center;
      width: 50%;
      color: #ffffff;

      .title {
        font-size: 70px;
        font-weight: 600;
        line-height: 97px;
        font-family: 'gfzt';
        letter-spacing: 10px;

        :nth-child(2n) {
          color: #91D200;
        }

        :nth-child(2n-1) {
          margin-left: 50px;
        }

      }

      .company {
        margin-top: 350px;
        font-size: 30px;
        font-family: Source Han Sans CN;
        font-weight: 600;
        line-height: 45px;
      }
    }
  }

  .introduce {
    width: 1500px;
    margin: 0 auto;

    .electronic {
      width: 100%;
      height: 300px;
      padding: 100px 0;
      background: url('../assets/img/about_bg.png') no-repeat bottom;

      .electronic_box {
        width: 95%;
        height: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;

        .left_box {
          width: 50%;
          padding: 0 2.5%;

          :nth-child(1),
          :nth-child(2) {
            color: #333333;
            font-weight: 600;
            font-size: 25px;
            text-align: left;
          }

          :nth-child(3) {
            margin-top: 10px;
            width: 40px;
            height: 4px;
            background: #13A3F0;
          }

          :last-child {
            margin-top: 10px;
            text-align: left;
            line-height: 30px;
            font-size: 14px;
          }
        }

        .right_box {
          width: 640px;
          height: 100%;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .option {
      width: 100%;
      height: 500px;
      margin-top: 50px;

      .title {
        display: flex;
        align-items: center;

        :first-child {
          width: 40px;
          height: 40px;
        }

        :nth-child(2) {
          color: #333333;
          font-size: 25px;
          font-weight: 600;
          margin-left: 20px;
        }

        :last-child {
          margin-left: 20px;
          color: #13A3F0;
          font-size: 18px;
        }
      }

      .option_box {
        width: 1400px;
        height: 400px;
        padding: 50px 60px;

        .plates {
          display: flex;
          align-items: center;

          :first-child {
            width: 105px;
            height: 105px;
            margin-left: 20px;
          }

          :last-child {
            margin-left: 30px;
            font-size: 20px;
            color: #333333;
            text-align: left;
          }
        }
      }
    }

    .business {
      width: 100%;
      // height: 600px;
      margin-top: 50px;

      .title {
        display: flex;
        align-items: center;

        :first-child {
          width: 40px;
          height: 40px;
        }

        :nth-child(2) {
          color: #333333;
          font-size: 25px;
          font-weight: 600;
          margin-left: 20px;
        }

        :last-child {
          margin-left: 20px;
          color: #13A3F0;
          font-size: 18px;
        }
      }

      .mickActive {
        display: none !important;
      }

      .mock {
        cursor: pointer;
        position: relative;
        top: 0;
        left: 0;
        height: 113px;

        .item {
          width: 300px;
          height: 50px;
          display: flex;
          align-items: center;

          img {
            width: 40px;
            height: 40px;
          }

          .box {
            margin-left: 20px;
            width: 100%;
            text-align: left;
            font-size: 12px;
            color: #7E7E7E;

            .title {
              font-size: 16px;
              color: #000;
              margin-bottom: 10px;
            }

            .describe {
              line-height: 20px;
              display: flex;
              align-items: center;

              :first-child {
                color: #13A3F0;
              }
            }
          }
        }
      }

      .business_box {
        width: 1400px;
        height: 500px;
        padding: 25px 60px;
        display: flex;

        .business_box_left {
          width: 40%;
          height: 500px;
          padding: 0px 10px;
          background: url('../assets/img/u3460.png') no-repeat bottom;
          background-size: 600px 450px;

          // display: flex;
          // flex-wrap: wrap;
          .list {
            width: 60%;
            height: 20%;
            overflow: hidden;
          }

          .recommend {
            margin: 30px 0;
            position: relative;
            width: 100%;
            display: flex;
            align-items: center;

            // height: 90;
            :first-child {
              width: 40px;
              height: 40px;
              margin-right: 20px;
            }

            :last-child {
              font-weight: 600;
              cursor: pointer;
            }
          }
        }

        .business_box_right {
          height: 500px;
          width: 60%;
          background-image: url('../assets/img/yiliao.jpg');
          background-size: cover;
          background-position: center;
          border-radius: 5px;
          opacity: 0.5;
        }
      }
    }

    .product {
      width: 100%;
      height: 700px;
      margin-top: 50px;
      position: relative;

      .title {
        display: flex;
        align-items: center;

        :first-child {
          width: 40px;
          height: 40px;
        }

        :nth-child(2) {
          color: #333333;
          font-size: 25px;
          font-weight: 600;
          margin-left: 20px;
        }

        :last-child {
          margin-left: 20px;
          color: #13A3F0;
          font-size: 18px;
        }
      }
      .matrix {
        height: 600px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 1400px;
        padding: 50px 60px 0 60px;
        .inner {
          position: relative;
          overflow: hidden;
          height: 250px;
          transition: 1.5s;
          &:hover {
            img{
              transition: 1s;
              transform:scale(1.1);
            }
          }
        }
        img {
          transition: 1s;
          width: 650px;
          height: 250px;
        }

        .text {
          transition: 1s;
          width: 580px;
          position: absolute;
          top: 40px;
          left: 30px;
          text-align: left;
          padding: 0 20px;

          :first-child {
            font-size: 30px;
            font-weight: 600;
            color: #000;
          }

          :last-child {
            margin-top: 10px;
            font-size: 22px;
            color: #555555;
          }
        }
      }

      .matrix_mock {
        pointer-events: none;
        display: none;
        transition: 1s;
        position: absolute;
        top: 90px;
        left: 0;
        width: 1400px;
        height: 405px;
        margin-left: 60px;
        background-color: rgb(0, 0, 0, .7);
        color: #ffffff;
        font-size: 30px;
        padding: 100px 0;
        line-height: 80px;
      }
    }

    .compete {
      overflow: hidden;
      width: 100%;
      height: 550px;
      // margin-top: 50px;

      .title {
        display: flex;
        align-items: center;

        :first-child {
          width: 40px;
          height: 40px;
        }

        :nth-child(2) {
          color: #333333;
          font-size: 25px;
          font-weight: 600;
          margin-left: 20px;
        }

        :last-child {
          margin-left: 20px;
          color: #13A3F0;
          font-size: 18px;
        }
      }

      .description {
        margin-top: 15px;
        color: #7E7E7E;
        text-align: left;
        margin-left: 60px;
      }

      .description_box {
        padding-left: 60px;
        height: 400px;
        width: 1440px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .inner_box {
          font-weight: 600px !important;
          width: 280px;
          height: 370px;
          box-shadow: 0 0 8px 0 rgba(232, 237, 250, .6), 0 2px 4px 0 rgba(232, 237, 250, .5);

          img {
            width: 280px;
            height: 280px;
            border-radius: 5px;
          }

          :last-child {
            margin-top: 25px;
            font-size: 16px;
            color: #333333;
          }
        }
      }
    }

    .cooperation {
      width: 1500px;
      height: 300px;
      // padding: 30px 60px;

      .title {
        display: flex;
        align-items: center;

        :first-child {
          width: 40px;
          height: 40px;
        }

        :nth-child(2) {
          color: #333333;
          font-size: 25px;
          font-weight: 600;
          margin-left: 20px;
        }

        :last-child {
          margin-left: 20px;
          color: #13A3F0;
          font-size: 18px;
        }
      }

      .partner {
        margin-top: 80px;
        width: 100%;
        overflow: hidden;
      }

      .swiper-wrapper {
        /* 这里是改变其动画效果为匀速形式，不加此样式的话会造成滚动卡顿，看起来效果不平滑 */
        /* 样式做了各种浏览器的兼容 */
        transition-timing-function: linear !important;
        -webkit-transition-timing-function: linear !important;
        -moz-transition-timing-function: linear !important;
        -ms-transition-timing-function: linear !important;
        -o-transition-timing-function: linear !important;
        transition-timing-function: linear !important;
      }

      .swiper-slide {
        width: 170px;
        height: 86px;
        // background: #FFFFFF;
        border: 1px solid #EFEFEF;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
</style>

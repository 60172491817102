<template>
    <div class="bottom"  v-show="webInfo.status==0">
        <div class="box_bottom">
            <div class="left_bottom">
                <div class="about wow animate__fadeInDown">
                    <div class="title">产品</div>
                    <div>米墨</div>
                    <div>电商</div>
                    <div>超医</div>
                </div>
                <div class="about wow animate__fadeInDown" >
                    <div class="title">联系我们</div>
                    <div>官网: {{ webInfo.companyOfficialWebsite }}</div>
                    <div>邮箱: {{webInfo.email  }}</div>
                    <div>地址: {{webInfo.address  }}</div>
                </div>
            </div>
            <div class="right_bottom" >
               <div style="width: 40%;">
                <div class="phone wow animate__fadeInDown">
                   <img src="../assets/img/landline.png" alt="">
                   <div>客服电话</div>
                   <div>(每周一至周日 8:30-18:30)</div>
                </div>
                <div class="phoneNumber">
                    {{webInfo.phone?webInfo.phone:'400 186 1200'  }}
                </div>
                  <!-- <div>(每周一至周日 8:30-18:30)</div> -->
               </div>
                <div class="scanCode">
                    <div class="imgCode wow animate__fadeInDown" v-if="webInfo.qrCode">
                        <img :src="webInfo.qrCode" alt="">
                        <div>官方公众号</div>
                    </div>
                    <div class="imgCode wow animate__fadeInDown" v-if="webInfo.qrCode2">
                        <img :src="webInfo.qrCode2" alt="" >
                        <div>官方视频号</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="record">
            {{webInfo.recordNumber }}
        </div>
    </div>
</template>

<script>
// import { WOW } from 'wowjs'
export default {
  props: ['webInfo'],
  data () {
    return {
    }
  }
}
</script>

<style lang="scss" scoped>
.bottom {
    width: 100%;
    height: 410px;
    background: #333333;
   .record{
    width: 100%;
    height: 50px;
    text-align: center;
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #CCCCCC;
    line-height: 50px;
   }
    .box_bottom{
        padding:0 10%;
        line-height: 45px;
        width: 80%;
        height: 360px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid  #3F3E3F;
        .left_bottom {
            width: 45%;
            display: flex;
            // justify-content: space-evenly;
            :first-child{
                    width: 200px;
                }
                :last-child{
                    width: 350px;
                }
            .about{
                text-align: left;
                .title{
                    font-size: 20px;
                    font-family: Source Han Sans CN;
                    font-weight: bold;
                    color: #FFFFFF;
                }
                div{
                    font-size: 16px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #ACAAAA;
                }
            }
        }
        .right_bottom {
          width: 50%;
          display: flex;
          height: 180px;
          .scanCode{
            width: 500px;
            display: flex;
            justify-content: space-evenly;
            img{
                width: 150px;
                height: 150px;
            }
            :last-child{
                text-align: center;
                font-size: 14px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #ACAAAA;
            }
          }
          .phoneNumber{
            font-family: 'gfzt';
            font-size: 40px;
            font-weight: 400;
            color: #13A3F0;
          }
          .phone{
            width: 300px;
           height: 45px;
           display: flex;
            align-items: center;
            flex-wrap: wrap;
           :first-child{
            width: 26px;
            height: 22px;
           }
            :last-child{
                margin-left: 10px;
                font-size: 14px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #FFFFFF;
            }
            :nth-child(2){
                margin-left: 9px;
                font-size: 20px;
                font-family: Source Han Sans CN;
                font-weight: bold;
                color: #FFFFFF;
            }
          }
        }
    }
}
</style>
